import React, { useEffect, useState, useMemo } from "react";
import {
  Outlet,
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { useFormTo } from "../../hooks/useFormTo";
import { useValidateCartPairMutation } from "../../store/wizardCreate/wizardCreateService";
import {
  setFromTo,
  setFromToParam,
  setFirstFromTo,
  setFirstRoute,
} from "../../store/wizardCreate/wizardCreateSlice";
import { getStoreFromRoute, authPath } from "../../utils";
import { isValidFromTo, PATTERN_FROM_TO_PARAMS } from "../../constants/params";

import LoadingModal from "../../components/LoadingModal";

const FromToProvider = () => {
  const { fromTo } = useParams();
  const { user } = useAuth();
  const { pathname, search, hash } = useLocation();
  const [searchParams] = useSearchParams();
  const { prefix, route } = useFormTo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isValidating, setIsValidating] = useState(true);
  const [validatePair, { isLoading, isFetching }] =
    useValidateCartPairMutation();

  const handleValidation = async (values) => {
    dispatch(setFromToParam({ prefix: fromTo ?? prefix }));
    dispatch(setFirstFromTo(fromTo ?? prefix));
    try {
      const res = await validatePair({
        ...values,
      }).unwrap();

      const payload = res?.payload;

      if (payload && typeof payload === "object") {
        const stores = {
          source: "",
          target: "",
          preview: "",
        };

        Object.keys(payload).forEach((key) => {
          const value = payload[key];
          if (value) {
            stores[key] = values?.[key];
          }
        });

        let fromToPath = "";

        if (values?.source && stores?.source) {
          fromToPath += `from-${values?.source}`;
        }

        if (values?.target && stores?.target) {
          fromToPath += `${fromToPath ? "-" : ""}to-${values?.target}`;
        }

        if (values?.preview && stores?.preview) {
          fromToPath += `-preview`;
        }

        dispatch(setFromTo(stores));
        dispatch(setFromToParam({ prefix: fromToPath ?? prefix }));
        localStorage.setItem("source", stores?.source);
        localStorage.setItem("target", stores?.target);
      }
    } catch (error) {
      setIsValidating(false);
      navigate("*");
    } finally {
      setIsValidating(false);
    }
  };

  const getParams = useMemo(
    () =>
      searchParams.get("name") || searchParams.get("price") ? search : "",
    [searchParams],
  );

  const fromToRules = (param) => {
    localStorage.setItem("source", "");
    localStorage.setItem("target", "");
    if (isValidFromTo(param)) {
      if (user?.id) {
        handleValidation({
          source: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "source",
          ),
          target: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "target",
          ),
          preview: getStoreFromRoute(
            param?.match(PATTERN_FROM_TO_PARAMS),
            "preview",
          ),
        });
      } else {
        dispatch(
          setFromToParam({
            prefix: param,
            route: `${pathname}${getParams}${hash}`,
          }),
        );
        if (authPath(pathname)) {
          dispatch(setFirstRoute(`${pathname}${getParams}${hash}`));
        }
        setIsValidating(false);
      }
    } else if (!user?.id) {
      dispatch(
        setFromToParam({
          prefix: param,
          route: `${pathname}${getParams}${hash}`,
        }),
      );
      if (authPath(pathname)) {
        dispatch(setFirstRoute(`${pathname}${getParams}${hash}`));
      }
      setIsValidating(false);
    } else {
      setIsValidating(false);
    }
  };

  useEffect(() => {
    fromToRules(fromTo ?? prefix);
  }, [user?.id]);

  if (
    isFetching ||
    isLoading ||
    (isValidating && !route && ![`/${prefix}`, `/${prefix}/`].includes(pathname))
  ) {
    return <LoadingModal isLoading />;
  }

  return <Outlet />;
};

export default FromToProvider;