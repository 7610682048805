import { BaseQueryParams } from "../baseQuery";

export const migrationsInfoService = BaseQueryParams("migrationsInfo", [
  "MIGRATIONS_INFO",
  "MIGRATIONS_TABLE",
]).injectEndpoints({
  endpoints: (builder) => ({
    getMigrationsSourceTables: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/store/source/tables`,
        method: "GET",
      }),

      providesTags: () => ["MIGRATIONS_INFO"],
    }),

    getMigrationsTargetTables: builder.query({
      query: ({ id }) => ({
        url: `/admin/migrations/${id}/store/target/tables`,
        method: "GET",
      }),

      providesTags: () => ["MIGRATIONS_INFO"],
    }),

    getMigrationsStoreTable: builder.query({
      query: ({ id, type, name, page, per_page, filters }) => ({
        url: `/admin/migrations/${id}/store/${type}/tables/${name}${
          page ? `?page=${page}` : ""
        }${per_page ? `&per_page=${per_page}` : ""}&config=1`,
        method: "GET",
        params: filters,
      }),

      providesTags: () => ["MIGRATIONS_TABLE"],
    }),

    getMigrationsStoreSearch: builder.query({
      query: ({ id, type, searchData }) => ({
        url: `/admin/migrations/${id}/store/${type}/search`,
        method: "GET",
        params: searchData,
      }),
    }),

    executeSQLQuery: builder.mutation({
      query: ({ id, type, body }) => ({
        url: `/admin/migrations/${id}/store/${type}/query`,
        method: "POST",
        body,
      }),
    }),

    getTableFields: builder.mutation({
      query: ({ id, type, name }) => ({
        url: `/admin/migrations/${id}/store/${type}/tables/fields/${name}`,
        method: "GET",
      }),
    }),

    updateTableFields: builder.mutation({
      query: ({ id, type, name, body }) => ({
        url: `/admin/migrations/${id}/store/${type}/tables/fields/${name}/update`,
        method: "PUT",
        body,
      }),

      invalidatesTags: () => ["MIGRATIONS_TABLE"],
    }),

    addTableFields: builder.mutation({
      query: ({ id, type, name, body }) => ({
        url: `/admin/migrations/${id}/store/${type}/tables/rows/${name}/add`,
        method: "POST",
        body,
      }),

      invalidatesTags: () => ["MIGRATIONS_TABLE"],
    }),

    deleteTableFields: builder.mutation({
      query: ({ id, type, name, body }) => ({
        url: `/admin/migrations/${id}/store/${type}/tables/fields/${name}/delete`,
        method: "DELETE",
        body,
      }),

      invalidatesTags: () => ["MIGRATIONS_TABLE"],
    }),
  }),
});

export const {
  useGetMigrationsSourceTablesQuery,
  useGetMigrationsTargetTablesQuery,
  useGetMigrationsStoreTableQuery,
  useGetMigrationsStoreSearchQuery,
  useExecuteSQLQueryMutation,
  useGetTableFieldsMutation,
  useUpdateTableFieldsMutation,
  useDeleteTableFieldsMutation,
  useAddTableFieldsMutation,
} = migrationsInfoService;
