import React, { memo } from "react";

import { StyledLink, Container, Text } from "./styles";

const DropdownLink = ({target, to, title, icon, border }) => (
  <StyledLink target={target} to={to} border={border ? 1 : 0}>
    <Container>
      {icon} <Text>{title}</Text>
    </Container>
  </StyledLink>
);

export default memo(DropdownLink);
