/* eslint-disable no-unsafe-optional-chaining */
import { SELECT_WITH_EXCEPTIONS } from "./wizard";
import { updateOptionItem } from "./select-store";
import { PATTERN_FROM_TO_PARAMS } from "../constants/params";

export const getPublickUrlByHost = (hostEnv) => {
  if (hostEnv === "develop") {
    return "https://staging.public.shopping-cart-migration.com/";
  }

  if (hostEnv === "staging") {
    return "https://staging.public.shopping-cart-migration.com/";
  }

  return "https://www.shopping-cart-migration.com/";
};

export const hasRequiredPermissions = (userPermissions, permissions) =>
  userPermissions?.some((requiredRole) => {
    const hasPermissions = permissions?.includes(requiredRole);

    return hasPermissions;
  });

export const downloadWithURL = (url) => {
  const aTag = document.createElement("a");
  aTag.href = url;
  const fileName = url.split("/").pop();
  aTag.setAttribute("download", fileName);
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();
};

export const transformYesNoParam = (type) => {
  const paramConfig = {
    No: "0",
    no: "0",
    Yes: "1",
    yes: "1",
  };

  return type ? paramConfig[type] : "";
};

export const changeStrImgId = (str) => {
  const text = str
    ?.toLowerCase()
    ?.replace(/ /g, "")
    ?.replace(":", "")
    ?.replace("!", "")
    ?.replace("(", "")
    ?.replace(")", "")
    ?.replace(/[\s.,%]/g, "");

  return text;
};

export const isMatch = (password, confirmNewPassword) =>
  password === confirmNewPassword ||
  "Your new password and verification password do not match";

export const changeValuesUpdateProfile = (values) => {
  const {
    firstname,
    lastname,
    email,
    gender,
    phone,
    country,
    industry,
    company,
    site,
    position,
  } = values;

  const data = {
    firstname,
    lastname,
    email,
    gender,
    phone,
    country,
    industry,
    company,
    site,
    position,
  };

  Object.filter = (obj, predicate) =>
    Object.keys(obj)
      .filter((key) => predicate(obj[key]))
      .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

  const filtered = Object.filter(data, (score) => !!score === true);

  return filtered;
};

export const isHaveDisabled = (name) => name === "options";

export const addSelectWithFields = (selected, data, field) => {
  let selectedArray = [...selected];

  data?.forEach((item) => {
    if (
      selectedArray.includes(item.key) &&
      !field.value.includes(item.key) &&
      !item?.fields_select_with?.length
    ) {
      item.children?.forEach((child) => {
        if (!selectedArray.includes(child.key)) {
          selectedArray.push(child.key);
        }
      });
    }

    if (!selectedArray.includes(item.key) && field.value.includes(item.key)) {
      item.children?.forEach((child) => {
        if (
          child.connect === "parent" ||
          child.connect === "lock" ||
          item?.fields_select_with?.length
        ) {
          selectedArray = selectedArray.filter((key) => key !== child.key);
        }
      });
    }

    item.children?.forEach((child) => {
      if (
        (child.connect === "parent" ||
          child.connect === "lock" ||
          item?.fields_select_with?.length) &&
        selectedArray.includes(child.key)
      ) {
        if (!selectedArray.includes(item.key)) {
          selectedArray.push(item.key);
        }
      }

      if (child.connect === "lock" && !selectedArray.includes(child.key)) {
        if (selectedArray.includes(item.key)) {
          selectedArray = selectedArray.filter((key) => key !== item.key);
        }
      }
    });

    if (item?.children?.length) {
      selectedArray = addSelectWithFields(selectedArray, item?.children, field);
    }
  });

  return selectedArray;
};

export const addRequiredItem = (selectedItems, data, field, name) => {
  let selected = [...selectedItems];

  data?.forEach((item) => {
    if (item?.required_options?.length) {
      const allValuesExist = item?.required_options?.every((value) =>
        selectedItems.includes(value),
      );

      if (
        allValuesExist &&
        !selectedItems.includes(item.key) &&
        !field.value.includes(item.key)
      ) {
        selected.push(item.key);
      }

      if (
        !allValuesExist &&
        selectedItems.includes(item.key) &&
        field.value.includes(item.key)
      ) {
        selected = selectedItems.filter((elem) => elem !== item.key);
      }

      if (
        allValuesExist &&
        !selectedItems.includes(item.key) &&
        field.value.includes(item.key)
      ) {
        selected = selectedItems.filter(
          (elem) => !item?.required_options.includes(elem),
        );
      }

      if (
        !allValuesExist &&
        selectedItems.includes(item.key) &&
        !field.value.includes(item.key)
      ) {
        selected.push(...item?.required_options);
      }
    }
  });

  const arrEnabled = data?.filter((elem) => elem?.disabled === false) || [];

  data?.forEach((elem) => {
    if (elem?.disabled === true && elem?.checked === true) {
      selected.push(elem?.key);
      arrEnabled.push(elem);
    }
  });

  const arrEnabledKeys = arrEnabled?.map((elem) => elem.key);
  const selectedWithArray = addSelectWithFields(selected, data, field);
  const resArray = [...new Set(selectedWithArray)];

  if (isHaveDisabled(name)) {
    return resArray.filter((elem) => arrEnabledKeys.includes(elem));
  }

  return resArray;
};

export const changeEmailsData = (data) => {
  const emails = data?.map((email) => ({
    value: email?.id,
    label: email?.email,
  }));

  return emails;
};

export const addPrefixToLinks = (prefix, links) => {
  if (prefix !== undefined) {
    links.forEach((link) => {
      links[link].to = `${prefix}/${links[link].to}`;
    });
  }

  return links;
};

export const setCookie = (cookieName, cookieValue, expirationDays) => {
  const date = new Date();
  date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${  name  }=([^;]+)`));
  return match ? match[2] : null;
};

export const getAllQueryParams = (searchParams) =>
  Array.from(searchParams?.keys()).reduce((params, paramName) => {
    params[paramName] = searchParams.get(paramName);

    return params;
  }, {});

export const getModifiedStore = (store) =>
  store?.charAt(0)?.toUpperCase() + store?.slice(1);

export const findTargetRegex = (routeMatch) => {
  const paramConfig = {
    to: routeMatch?.groups?.first || "",
    from: routeMatch?.groups?.second || "",
  };
  return routeMatch?.groups?.firstPrefix
    ? paramConfig[routeMatch?.groups?.firstPrefix]
    : "";
};

export const getStoreFromRoute = (routeMatch, store) => {
  const paramConfig = {
    source:
      routeMatch?.groups?.firstPrefix === "from"
        ? routeMatch?.groups?.first
        : "",
    target:
      routeMatch?.groups?.first !== "preview"
        ? findTargetRegex(routeMatch)
        : "",
    preview: routeMatch?.groups?.preview !== undefined
        ? findTargetRegex(routeMatch)
        : "",
  };

  return store ? paramConfig[store] : "";
};

export const getFromToSelector = (prefix) => ({
  source: getStoreFromRoute(
    prefix?.match(PATTERN_FROM_TO_PARAMS),
    "source",
  ),
  target: getStoreFromRoute(
    prefix?.match(PATTERN_FROM_TO_PARAMS),
    "target",
  ),
  preview: getStoreFromRoute(
    prefix?.match(PATTERN_FROM_TO_PARAMS),
    "preview",
  ),
});


export const removeFromToPrefix = (prefix, type) => {
  const stores = getFromToSelector(prefix);

  let fromToPath = "";

  if (stores?.source && type !== "source") {
    fromToPath += `from-${stores?.source}`;
  }

  if (stores?.target && type !== "target") {
    fromToPath += `${fromToPath ? "-" : ""}to-${stores?.target}`;
  }

  if (stores?.preview && type !== "target") {
    fromToPath += `-preview`;
  }

  return fromToPath;
};

export const getUniqueCarts = (source, target, WrapperCarts) => {
  const allCarts = [...source, ...target];

  const uniqueCarts = [...new Set(allCarts.map((item) => item.value))]
    .map((value) => allCarts.find((item) => item.value === value))
    .sort((a, b) => a.value.localeCompare(b.value));

  return updateOptionItem(uniqueCarts, WrapperCarts);
};

const handlePageScroll = (setHiddenMenu, e) => {
  const { target } = e;
  const { scrollTop } = target;

  if (scrollTop > 64) {
    setHiddenMenu(true);
  } else {
    setHiddenMenu(false);
  }
};

export const getScrollPage = (ref, setHiddenMenu) => {
  const element = ref.current;
  const handleScroll = (e) => handlePageScroll(setHiddenMenu, e);

  if (element) {
    element.addEventListener("scroll", handleScroll);
  }

  return () => {
    if (element) {
      element.removeEventListener("scroll", handleScroll);
    }
  };
};

export const authErrorMessages = {
  toManyAttempts: "Too Many Attempts.",
};

export const updateSelectedRows = (
  newSelectedRows,
  dataSource,
  field,
  onValueChange,
) => {
  field.onChange(addRequiredItem(newSelectedRows, dataSource, field));
  if (onValueChange) {
    onValueChange(addRequiredItem(newSelectedRows, dataSource, field));
  }
};

export const collectAllKeys = (items) => {
  let keys = [];

  items?.forEach((item) => {
    keys.push(item.key);
    if (item.children) {
      keys = keys.concat(collectAllKeys(item.children));
    }
  });

  return keys;
};

export const WIX_ENV = [
  "wixmodule",
  "wixmodule_staging",
  "wixmodule_develop",
];

export const SHOPIFY_ENV = [
  "shopifymodule",
  "shopifymodule_staging",
  "shopifymodule_develop",
];

export const authPath = (path) => path !== "/oauth/google/callback";
