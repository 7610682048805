import React, {useEffect, useLayoutEffect, useMemo} from "react";
import { useCookies } from "react-cookie";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useGetConfigQuery } from "../../store/settings/settingsService";
import { usePublicConfig } from "../../hooks/usePublicConfig";
import { setWixGetParam, setStoreUrl, setStoreAdminUrl } from "../../store/settings/settingsSlice";
import { WIX_ENV } from "../../utils";

const SettingsProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["_C2C_REFERER"]);
  const location = useLocation();
  const { env } = usePublicConfig();
  const dispatch = useDispatch();
  const searchParamsObject = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const { referrer } = window.document;
  const { isLoading } = useGetConfigQuery({});

  useEffect(() => {
    const ref = window.document;

    setCookie("_C2C_REFERER", ref.referrer, {
      path: "/",
    });
  }, []);

  useEffect(() => {
    if (referrer) {
      setCookie("_C2C_REFERER", referrer, {
        path: "/",
      });
    }
  }, [referrer]);

  useEffect(() => {
    if (!WIX_ENV?.includes(env?.hostEnv)) return;

    if (Object.keys(searchParamsObject || {}).length > 0) {
      dispatch(setWixGetParam(searchParamsObject));
    }
  }, [searchParamsObject]);

  useLayoutEffect(() => {
    dispatch(setStoreUrl(searchParamsObject?.storeUrl || ""));
  }, [searchParamsObject?.storeUrl]);


  useLayoutEffect(() => {
    dispatch(setStoreAdminUrl(searchParamsObject?.storeAdminUrl || ""));
  }, [searchParamsObject?.storeAdminUrl]);

  return children;
};

export default SettingsProvider;
