import React from "react";
import { UserOutlined } from "@ant-design/icons";

import { useAuth } from "../../../hooks/useAuth";
import { ReactComponent as DotsVerticalIcon } from "../../../assets/images/DotsVerticalIcon.svg";
import UserMenuDropdown from "../../UserMenuDropdown";

import {
  Container,
  Wrapper,
  StyledAvatar,
  UserName,
  ContainerIcon,
} from "./styles";

const HeaderUserAvatar = () => {
  const { user } = useAuth();
  const timestamp = new Date().getTime();

  return (
    <UserMenuDropdown>
      <Container>
        <Wrapper>
          <StyledAvatar
            icon={
              user?.avatar ? (
                <img src={`${user?.avatar}?${timestamp}`} alt="avatar" />
              ) : (
                <UserOutlined />
              )
            }
          />

          <UserName>{user?.username}</UserName>
        </Wrapper>

        <ContainerIcon>
          <DotsVerticalIcon />
        </ContainerIcon>
      </Container>
    </UserMenuDropdown>
  );
};

export default HeaderUserAvatar;