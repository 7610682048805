import React, { useCallback } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { USER_PATH } from "../../router/path/user";
import { useAuth } from "../../hooks/useAuth";
import { useFormTo } from "../../hooks/useFormTo";
import { selectSettings } from "../../store/settings/settingsSlice";

import { setRightRoute } from "../../utils/providers";
import { selectFirstRoute } from "../../store/wizardCreate/wizardCreateSlice";

const AuthProvider = () => {
  const { user } = useAuth();
  const { options } = useSelector(selectSettings);
  const { prefix, route } = useFormTo();
  const firstRoute = useSelector(selectFirstRoute);

  const getRedirect = useCallback(() => {
    const finalPath =
      !user?.has_migrations
        ? USER_PATH.MI_START_MIGRATION_PAGE
        : USER_PATH.MY_MIGRATIONS_LIST;

    if (
      !user?.business_role &&
      !options?.business_role?.required_in_popup
    ) {
      return USER_PATH.REGISTRATION_QUIZ;
    }
    return setRightRoute(prefix, route, firstRoute) || finalPath;
  }, [user]);

  return user?.id ? <Navigate to={getRedirect()} replace /> : <Outlet />;
};

export default AuthProvider;
