export const STATUS_CREATED = "created";
export const STATUS_CREATED_RECENT = "created_recent";
export const STATUS_CREATED_REMIGRATION = "created_remigration";
export const STATUS_EDITED = "edited";
export const STATUS_EDITED_RECENT = "edited_recent";
export const STATUS_EDITED_REMIGRATION = "edited_remigration";
export const STATUS_PENDING = "pending";
export const STATUS_PENDING_RECENT = "pending_recent";
export const STATUS_PENDING_REMIGRATION = "pending_remigration";
export const STATUS_WAITING_FOR_CLIENTS_REPLY = "waiting_for_clients_reply";
export const STATUS_WAITING_FOR_CLIENTS_REPLY_REMIGRATION =
  "waiting_for_clients_reply_remigration";
export const STATUS_WAITING_FOR_DEMO_PROGRESS = "wait_for_demo";
export const STATUS_IN_DEMO_PROGRESS = "in_demo_progress";
export const STATUS_DEMO_ERROR = "demo_error";
export const STATUS_DEMO_COMPLETED = "demo_completed";
export const STATUS_WAITING_FOR_DEMO_PROGRESS_REMIGRATION =
  "wait_for_demo_remigration";
export const STATUS_IN_DEMO_PROGRESS_REMIGRATION =
  "in_demo_progress_remigration";
export const STATUS_DEMO_ERROR_REMIGRATION = "demo_error_remigration";
export const STATUS_DEMO_COMPLETED_REMIGRATION = "demo_completed_remigration";
export const STATUS_WAITING_FOR_PROGRESS = "wait_for_progress";
export const STATUS_WAITING_FOR_PROGRESS_RECENT = "wait_for_progress_recent";
export const STATUS_WAITING_FOR_PROGRESS_REMIGRATION =
  "wait_for_progress_remigration";
export const STATUS_IN_PROGRESS = "in_progress";
export const STATUS_IN_PROGRESS_RECENT = "in_progress_recent";
export const STATUS_IN_PROGRESS_REMIGRATION = "in_progress_remigration";
export const STATUS_ERROR = "error";
export const STATUS_ERROR_RECENT = "error_recent";
export const STATUS_ERROR_REMIGRATION = "error_remigration";
export const STATUS_PAUSED = "paused";
export const STATUS_COMPLETED = "completed";
export const STATUS_COMPLETED_RECENT = "completed_recent";
export const STATUS_COMPLETED_REMIGRATION = "completed_remigration";
// Back to migration progress after pause
export const STATUS_TO_PROGRESS = "to_progress";
export const STATUS_TO_PROGRESS_RECENT = "to_progress_recent";
export const STATUS_TO_PROGRESS_REMIGRATION = "to_progress_recmigration";
// for payment new statuses
export const STATUS_REFUNDED = "refunded";
export const STATUS_REFUNDED_REMIGRATION = "refunded_remigration";
export const STATUS_FRAUD = "fraud";
export const STATUS_FRAUD_REMIGRATION = "fraud_remigration";
export const STATUS_WAIT_FOR_DEMO_FILES_COPIER = "wait_for_demo_files_copier";
export const STATUS_WAIT_FOR_DEMO_FILES_COPIER_REMIGRATION =
  "wait_for_demo_files_copier_remigration";
export const STATUS_WAIT_FOR_FILES_COPIER = "wait_for_files_copier";
export const STATUS_WAIT_FOR_FILES_COPIER_REMIGRATION =
  "wait_for_files_copier_remigration";
export const STATUS_WAIT_FOR_FILES_COPIER_RECENT =
  "wait_for_files_copier_recent";
export const STATUS_RETRIEVING_SOURCE_RESOURCES = "retrieving_source_resources";
export const STATUS_ENTITY_ERROR = "entity_error";

export const MIGRATION_STATUS = {
  [STATUS_WAITING_FOR_CLIENTS_REPLY]: {
    background: "#FEF9C3",
    color: "#EAB308",
    status: "Waiting for client's reply",
  },
  [STATUS_WAITING_FOR_CLIENTS_REPLY_REMIGRATION]: "Waiting for client's reply",
  [STATUS_PAUSED]: {
    background: "#F4EBFF",
    color: "#42307D",
    status: "Migration paused",
  },
  [STATUS_REFUNDED]: {
    background: "#F4EBFF",
    color: "#42307D",
    status: "Migration refunded",
  },
  [STATUS_REFUNDED_REMIGRATION]: "Migration refunded",
  [STATUS_FRAUD]: {
    background: "#ECFEFF",
    color: "#164E63",
    status: "Migration fraud",
  },
  [STATUS_FRAUD_REMIGRATION]: "Migration fraud",
  [STATUS_RETRIEVING_SOURCE_RESOURCES]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Backuping Source Store data",
  },

  [STATUS_WAITING_FOR_DEMO_PROGRESS]: {
    background: "#E8F6FC",
    color: "#044663",
    status: "Demo Migration is about to start",
  },
  [STATUS_IN_DEMO_PROGRESS]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Demo Migration is in progress",
  },
  [STATUS_DEMO_ERROR]: {
    background: "#FECDCA",
    color: "#B42318",
    status: "Demo Migration was suspended",
  },
  [STATUS_DEMO_COMPLETED]: {
    background: "#BBF7D0",
    color: "#166534",
    status: "Demo migration completed",
  },
  [STATUS_WAIT_FOR_DEMO_FILES_COPIER]: {
    background: "#EBEFFF",
    color: "#42307D",
    status: "Waiting for demo files copier",
  },

  [STATUS_WAITING_FOR_DEMO_PROGRESS_REMIGRATION]: {
    background: "#E8F6FC",
    color: "#044663",
    status: "Demo Remigration is about to start",
  },
  [STATUS_IN_DEMO_PROGRESS_REMIGRATION]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Demo Remigration is in progress",
  },
  [STATUS_DEMO_ERROR_REMIGRATION]: {
    background: "#FECDCA",
    color: "#B42318",
    status: "Demo Remigration was suspended",
  },
  [STATUS_DEMO_COMPLETED_REMIGRATION]: {
    background: "#BBF7D0",
    color: "#166534",
    status: "Demo Remigration completed",
  },
  [STATUS_WAIT_FOR_DEMO_FILES_COPIER_REMIGRATION]: {
    background: "#EBEFFF",
    color: "#42307D",
    status: "Waiting for demo remigration files copier",
  },

  [STATUS_CREATED]: {
    background: "#E9D7FE",
    color: "#6941C6",
    status: "New",
  },
  [STATUS_CREATED_RECENT]: {
    background: "#E9D7FE",
    color: "#6941C6",
    status: "New Recent Data",
  },
  [STATUS_CREATED_REMIGRATION]: {
    background: "#E9D7FE",
    color: "#6941C6",
    status: "New Remigration of Migration #__PARENT__",
  },

  [STATUS_EDITED]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Modified",
  },
  [STATUS_EDITED_RECENT]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Modified Recent Data Migration",
  },
  [STATUS_EDITED_REMIGRATION]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Modified Remigration of Migration #__PARENT__",
  },

  [STATUS_PENDING]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Migration Pending",
  },
  [STATUS_PENDING_RECENT]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Recent Data Migration Pending",
  },
  [STATUS_PENDING_REMIGRATION]: {
    background: "#FEF08A",
    color: "#D97706",
    status: "Remigration Pending",
  },

  [STATUS_WAITING_FOR_PROGRESS]: {
    background: "#E8F6FC",
    color: "#044663",
    status: "Full Migration is about to start",
  },
  [STATUS_WAITING_FOR_PROGRESS_RECENT]: {
    background: "#E8F6FC",
    color: "#044663",
    status: "Recent Data Migration is about to start",
  },
  [STATUS_WAITING_FOR_PROGRESS_REMIGRATION]: {
    background: "#E8F6FC",
    color: "#044663",
    status: "Remigration of Migration #__PARENT__ is about to start",
  },

  [STATUS_IN_PROGRESS]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Full Migration is in progress",
  },
  [STATUS_IN_PROGRESS_RECENT]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Recent Data Migration is in progress",
  },
  [STATUS_IN_PROGRESS_REMIGRATION]: {
    background: "#E0F2FE",
    color: "#0B4A6F",
    status: "Remigration of Migration #__PARENT__ is in progress",
  },

  [STATUS_ERROR]: {
    background: "#FECDCA",
    color: "#B42318",
    status: "Full Migration was suspended",
  },
  [STATUS_ERROR_RECENT]: {
    background: "#FECDCA",
    color: "#B42318",
    status: "Recent Data Migration was suspended",
  },
  [STATUS_ERROR_REMIGRATION]: {
    background: "#FECDCA",
    color: "#B42318",
    status: "Remigration of Migration #__PARENT__ was suspended",
  },

  [STATUS_TO_PROGRESS]: {
    background: "#FCEAFF",
    color: "#2F0748",
    status: "Full Migration is in progress (Pause)",
  },
  [STATUS_TO_PROGRESS_RECENT]: {
    background: "#FCEAFF",
    color: "#2F0748",
    status: "Recent Data Migration is in progress (Pause)",
  },
  [STATUS_TO_PROGRESS_REMIGRATION]: {
    background: "#FCEAFF",
    color: "#2F0748",
    status: "Remigration of Migration #__PARENT__ is in progress (Pause)",
  },

  [STATUS_COMPLETED]: {
    background: "#BBF7D0",
    color: "#166534",
    status: "Full Migration completed",
  },
  [STATUS_COMPLETED_RECENT]: {
    background: "#BBF7D0",
    color: "#166534",
    status: "Recent Data Migration completed",
  },
  [STATUS_COMPLETED_REMIGRATION]: {
    background: "#BBF7D0",
    color: "#166534",
    status: "Remigration of Migration #__PARENT__ completed",
  },

  [STATUS_WAIT_FOR_FILES_COPIER]: {
    background: "#EBEFFF",
    color: "#42307D",
    status: "Waiting for files copier",
  },
  [STATUS_WAIT_FOR_FILES_COPIER_RECENT]: {
    background: "#EBEFFF",
    color: "#42307D",
    status: "Recent data migrations is waiting for files copier",
  },
  [STATUS_WAIT_FOR_FILES_COPIER_REMIGRATION]: {
    background: "#EBEFFF",
    color: "#42307D",
    status: "Remigration waiting for files copier",
  },

  [STATUS_ENTITY_ERROR]: "Entity Migration was suspended",
};
