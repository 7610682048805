import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

import { ROOT, AUTH_PATH, FROM_TO_PATH } from "./path/auth";
import { USER_PATH } from "./path/user";
import { MIGRATIONS_PATH } from "./path/migrations";
import { ADMIN_PATH } from "./path/admin";
import { useFormTo } from "../hooks/useFormTo";

import LazyLayout from "../components/Layouts/LazyLayout";
import AuthProvider from "../providers/AuthProvider";
import NoAuthProvider from "../providers/NoAuthProvider";
import PermissionsProvider from "../providers/PermissionsProvider";
import FromToProvider from "../providers/FromToProvider";

// pages
import Authorization from "../pages/Authorization";

// pages lazy
const NotFound = lazy(() => import("../pages/NotFound"));
const UserMigrations = lazy(() => import("../pages/UserMigrations"));
const ComplaintPage = lazy(() => import("../pages/ComplaintPage"));
const Wizard = lazy(() => import("../pages/Wizard"));
const ShopifyModule = lazy(() => import("../pages/ShopifyModule"));
const AdminAeroSql = lazy(() => import("../pages/Admins/AdminAeroSql"));
const ErrorPage = lazy(() => import("../pages/ErrorPage"));
// admin pages
const Configuration = lazy(() => import("../pages/Admins/Configuration"));
const AdminLogs = lazy(() => import("../pages/Admins/AdminLogs"));
const MigrationsSurveys = lazy(() =>
  import("../pages/Admins/MigrationsSurveys"),
);
const ReportsWizardLogs = lazy(() =>
  import("../pages/Admins/ReportsWizardLogs"),
);
const PriceRules = lazy(() => import("../pages/Admins/PriceRules"));
const Mirrors = lazy(() => import("../pages/Admins/Mirrors"));
const AdminMigrations = lazy(() => import("../pages/Admins/AdminMigrations"));
const MigrationsInstances = lazy(() =>
  import("../pages/Admins/MigrationsInstances"),
);
const ConfigPackages = lazy(() => import("../pages/Admins/ConfigPackages"));
const SupportedEntities = lazy(() =>
  import("../pages/Admins/SupportedEntities"),
);
const Translations = lazy(() => import("../pages/Admins/Translations"));
const Accounts = lazy(() => import("../pages/Admins/Accounts"));
const LoyaltyUsers = lazy(() => import("../pages/Admins/LoyaltyUsers"));
const AccountsEstimatesList = lazy(() =>
  import("../pages/Admins/AccountsEstimatesList"),
);
const BalanceHistory = lazy(() => import("../pages/Admins/BalanceHistory"));
const AccountsGroups = lazy(() => import("../pages/Admins/AccountsGroups"));
const EstimatorPriceMultipliers = lazy(() =>
  import("../pages/Admins/EstimatorPriceMultipliers"),
);
const ConfigCurrencies = lazy(() => import("../pages/Admins/ConfigCurrencies"));
const MigrationInsurances = lazy(() =>
  import("../pages/Admins/MigrationInsurances"),
);
const CartPairSeoTexts = lazy(() => import("../pages/Admins/CartPairSeoTexts"));
const CartUpgradeSeoTexts = lazy(() =>
  import("../pages/Admins/CartUpgradeSeoTexts"),
);
const RegistrationAddFunds = lazy(() =>
  import("../pages/Admins/RegistrationAddFunds"),
);
const MigrationBlock = lazy(() => import("../pages/Admins/MigrationBlock"));
const EntitiesPacks = lazy(() => import("../pages/Admins/EntitiesPacks"));
const ReportsLogs = lazy(() => import("../pages/Admins/ReportsLogs"));
const ReportsErrorStatistics = lazy(() =>
  import("../pages/Admins/ReportsErrorStatistics"),
);
const OptionsInsuracesRecentData = lazy(() =>
  import("../pages/Admins/OptionsInsuracesRecentData"),
);
const AdminCoupons = lazy(() => import("../pages/Admins/AdminCoupons"));
const SmartMapping = lazy(() => import("../pages/Admins/SmartMapping"));
const Mappings = lazy(() => import("../pages/Admins/Mappings"));
const AdminServicesReports = lazy(() =>
  import("../pages/Admins/AdminServicesReports"),
);

const Roles = lazy(() => import("../pages/Admins/Roles"));
const Permissions = lazy(() => import("../pages/Admins/Permissions"));
const MigrationsStarter = lazy(() =>
  import("../pages/Admins/MigrationsStarter"),
);
const Access = lazy(() => import("../pages/Admins/Access"));
const AccessSuccess = lazy(() => import("../pages/Admins/AccessSuccess"));
const SurveyTicket = lazy(() => import("../pages/Admins/SurveyTicket"));
const AccountsQueue = lazy(() => import("../pages/Admins/AccountsQueue"));

const Feedback = lazy(() => import("../pages/Feedback"));
const RegistrationQuiz = lazy(() => import("../pages/RegistrationQuiz"));
const AdminDashboard = lazy(() => import("../pages/Admins/AdminDashboard"));
const MigrationLogs = lazy(() => import("../pages/MigrationLogs"));
const FirstScreen = lazy(() => import("../pages/FirstScreen"));
const MigrationDetails = lazy(() => import("../pages/MigrationDetails"));
const MigrationDemoRate = lazy(() => import("../pages/MigrationDemoRate"));
const Profile = lazy(() => import("../pages/Profile"));
const MigrationServicePurchase = lazy(() =>
  import("../pages/MigrationServicePurchase"),
);
const MigrationServicePurchaseThankYouPage = lazy(() =>
  import("../pages/MigrationServicePurchaseThankYouPage"),
);
const MigrationsMappingClear = lazy(() =>
  import("../pages/MigrationsMappingClear"),
);
const InvoiceInfo = lazy(() => import("../pages/InvoiceInfo"));
const MigrationRestart = lazy(() => import("../pages/MigrationRestart"));

const AdminStores = lazy(() => import("../pages/Admins/AdminStores"));
const AdminPreparedStores = lazy(() =>
  import("../pages/Admins/AdminPreparedStores"),
);
const AdminPreviewConfig = lazy(() =>
  import("../pages/Admins/AdminPreviewConfig"),
);
const AdminPreviewLogs = lazy(() => import("../pages/Admins/AdminPreviewLogs"));
const AdminPreviewReport = lazy(() =>
  import("../pages/Admins/AdminPreviewReport"),
);
const AdminMigrationsIssues = lazy(() =>
  import("../pages/Admins/AdminMigrationsIssues"),
);
const ReportsDemoMigrationsRates = lazy(() =>
  import("../pages/Admins/ReportsDemoMigrationsRates"),
);
const ReportsCoupons = lazy(() => import("../pages/Admins/ReportsCoupons"));
const ReportsWizardStats = lazy(() =>
  import("../pages/Admins/ReportsWizardStats"),
);
const ReportsProxiesStatistics = lazy(() =>
  import("../pages/Admins/ReportsProxiesStatistics"),
);
const ReportsRemigrationStats = lazy(() =>
  import("../pages/Admins/ReportsRemigrationStats"),
);
const ReportsWizardStepsFunnel = lazy(() =>
  import("../pages/Admins/ReportsWizardStepsFunnel"),
);
const ReportsTicketsSurvey = lazy(() =>
  import("../pages/Admins/ReportsTicketsSurvey"),
);
const ReportsDebugStatistics = lazy(() =>
  import("../pages/Admins/ReportsDebugStatistics"),
);
const AccountsFeedback = lazy(() => import("../pages/Admins/AccountsFeedback"));

const PaymentProcess = lazy(() => import("../pages/PaymentProcess"));

const ChangePassword = lazy(() => import("../pages/ChangePassword"));

const AuthorizationLogout = lazy(() => import("../pages/AuthorizationLogout"));

const AdminFilesDownload = lazy(() =>
  import("../pages/Admins/AdminFilesDownload"),
);

const AppRouter = () => {
  const { prefix } = useFormTo();

  return (
    <Routes>
      <Route path={ROOT} element={<LazyLayout />}>
        <Route path={FROM_TO_PATH} element={<FromToProvider />}>
          <Route path={prefix}>
            <Route element={<NoAuthProvider />}>
              <Route index element={<Authorization />} />
              <Route path={AUTH_PATH.LOGIN} element={<Authorization />} />
              <Route path={AUTH_PATH.LOGIN_TYPE} element={<Authorization />} />
              <Route
                path={AUTH_PATH.OAUTH_CALLBACK}
                element={<Authorization />}
              />
              <Route
                path={AUTH_PATH.OAUTH_CALLBACK_FACEBOOK}
                element={<Authorization />}
              />
              <Route
                path={AUTH_PATH.OAUTH_CALLBACK_GOOGLE}
                element={<Authorization />}
              />
              <Route path={AUTH_PATH.AUTH} element={<Authorization />} />
              <Route
                path={AUTH_PATH.CHANGE_PASSWORD}
                element={<ChangePassword />}
              />
              <Route path="*" element={<ErrorPage />} />
            </Route>
            <Route element={<AuthProvider />}>
              <Route
                path={USER_PATH.COMPLAINT_FORM}
                element={<ComplaintPage />}
              />
              <Route
                path={USER_PATH.REGISTRATION_QUIZ}
                element={<RegistrationQuiz />}
              />
              <Route
                path={USER_PATH.MY_MIGRATIONS_LIST}
                element={<UserMigrations />}
              />
              <Route
                path={USER_PATH.LOYALTY_USERS}
                element={<LoyaltyUsers />}
              />
              <Route
                path={MIGRATIONS_PATH.MIGRATIONS}
                element={<UserMigrations />}
              />
              <Route
                path={MIGRATIONS_PATH.MIGRATION_LOG_BY_ID}
                element={<MigrationLogs />}
              />
              <Route path={ADMIN_PATH.FEEDBACK} element={<Feedback />} />
              <Route
                path={USER_PATH.MI_START_MIGRATION_PAGE}
                element={<FirstScreen />}
              />
              <Route
                path={USER_PATH.MY_MIGRATION_DETAILS_BY_ID}
                element={<MigrationDetails />}
              />
              <Route
                path={USER_PATH.MY_MIGRATION_DEMO_RATE_BY_ID}
                element={<MigrationDemoRate />}
              />
              <Route path={USER_PATH.PROFILE} element={<Profile />} />
              <Route
                path={USER_PATH.PAYMENTS_PROCESS}
                element={<PaymentProcess />}
              />
              <Route
                path={USER_PATH.PAYMENTS_SHOPIFY_IPN}
                element={<PaymentProcess />}
              />
              <Route
                path={USER_PATH.DATA_MIGRATION_PAGE}
                element={<MigrationServicePurchase />}
              />
              <Route
                path={USER_PATH.DATA_MIGRATION_PAGE_THANK_YOU_PAGE}
                element={<MigrationServicePurchaseThankYouPage />}
              />
              <Route
                path={USER_PATH.MAPPINGS_BY_MIGRATION_ID}
                element={<Mappings />}
              />
              <Route
                path={USER_PATH.MIGRATIONS_MAPPING_CLEAR}
                element={<MigrationsMappingClear />}
              />
              <Route
                path={USER_PATH.UNSUBSCRIBE}
                element={<h1 style={{ textAlign: "center" }}>UNSUBSCRIBE</h1>}
              />
              <Route path={USER_PATH.INVOICE} element={<InvoiceInfo />} />
              <Route
                path={USER_PATH.RESTART_MIGRATION_BY_ID}
                element={<MigrationRestart />}
              />

              <Route path={USER_PATH.WIZARD} element={<Wizard />} />
              <Route path={USER_PATH.WIZARD_STEP} element={<Wizard />} />
              <Route
                path={`${USER_PATH.WIZARD_STEP}/:migrationId`}
                element={<Wizard />}
              />

              {/* admin pages */}
              <Route element={<PermissionsProvider />}>
                <Route
                  path={ADMIN_PATH.CONFIGURATION}
                  element={<Configuration />}
                />
                <Route path={ADMIN_PATH.ADMIN_LOGS} element={<AdminLogs />} />
                <Route
                  path={ADMIN_PATH.ADMIN_MIGRATION_SURVEYS}
                  element={<MigrationsSurveys />}
                />
                <Route
                  path={ADMIN_PATH.MI_REPORTS_WIZARD_LOGS}
                  element={<ReportsWizardLogs />}
                />
                <Route
                  path={ADMIN_PATH.MI_PRICE_RULES}
                  element={<PriceRules />}
                />
                <Route path={ADMIN_PATH.MIRRORS} element={<Mirrors />} />
                <Route
                  path={ADMIN_PATH.ADMIN_MIGRATION}
                  element={<AdminMigrations />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_MIGRATION_BY_ACCOUNT_ID}
                  element={<AdminMigrations />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_MIGRATION_BY_MIGRATION_ID}
                  element={<AdminMigrations />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_INSTANCES}
                  element={<MigrationsInstances />}
                />
                <Route
                  path={ADMIN_PATH.PACKAGES}
                  element={<ConfigPackages />}
                />
                <Route
                  path={ADMIN_PATH.SUPPORTED_ENTITIES}
                  element={<SupportedEntities />}
                />
                <Route
                  path={ADMIN_PATH.TRANSLATIONS}
                  element={<Translations />}
                />
                <Route path={ADMIN_PATH.ACCOUNTS} element={<Accounts />} />
                <Route
                  path={ADMIN_PATH.ESTIMATES}
                  element={<AccountsEstimatesList />}
                />
                <Route
                  path={ADMIN_PATH.BALANCE_HISTORY}
                  element={<BalanceHistory />}
                />
                <Route path={ADMIN_PATH.GROUPS} element={<AccountsGroups />} />
                <Route
                  path={ADMIN_PATH.ESTIMATOR_PRICE_MULTIPLIERS}
                  element={<EstimatorPriceMultipliers />}
                />
                <Route
                  path={ADMIN_PATH.CURRENCIES}
                  element={<ConfigCurrencies />}
                />
                <Route
                  path={ADMIN_PATH.MIGRATION_INSURANCES}
                  element={<MigrationInsurances />}
                />
                <Route
                  path={ADMIN_PATH.CARTS_SEO_TEXTS}
                  element={<CartPairSeoTexts />}
                />
                <Route
                  path={ADMIN_PATH.CARTS_UPGRADE_SEO_TEXTS}
                  element={<CartUpgradeSeoTexts />}
                />
                <Route
                  path={ADMIN_PATH.REGISTRATION_ADD_FUNDS}
                  element={<RegistrationAddFunds />}
                />
                <Route
                  path={ADMIN_PATH.MIGRATION_BLOCK}
                  element={<MigrationBlock />}
                />
                <Route
                  path={ADMIN_PATH.ENTITIES_PACKS}
                  element={<EntitiesPacks />}
                />
                <Route
                  path={ADMIN_PATH.REPORTS_LOGS}
                  element={<ReportsLogs />}
                />
                <Route
                  path={ADMIN_PATH.DEBUG_STATISTICS}
                  element={<ReportsDebugStatistics />}
                />
                <Route
                  path={ADMIN_PATH.REPORTS_WIZARD_LOGS_STATISTICS}
                  element={<h1>REPORTS_WIZARD_LOGS_STATISTICS</h1>}
                />
                <Route
                  path={ADMIN_PATH.REPORTS_ERRORS_STATISTICS}
                  element={<ReportsErrorStatistics />}
                />
                <Route
                  path={ADMIN_PATH.PROTRACTOR_STATISTICS}
                  element={<h1>PROTRACTOR_STATISTICS</h1>}
                />
                <Route
                  path={ADMIN_PATH.UNSUBSCRIBERS}
                  element={<h1>ReportsUnsubscribers</h1>}
                />
                <Route
                  path={ADMIN_PATH.TICKETS_SURVEY}
                  element={<ReportsTicketsSurvey />}
                />
                <Route
                  path={ADMIN_PATH.REFERRAL_URL_STATISTICS}
                  element={<h1>ReportsReferralURLStatistics</h1>}
                />
                <Route path={ADMIN_PATH.QUEUE} element={<AccountsQueue />} />
                <Route
                  path={ADMIN_PATH.OPTIONS_INSURACES_RECENT_DATA}
                  element={<OptionsInsuracesRecentData />}
                />
                <Route path={ADMIN_PATH.COUPONS} element={<AdminCoupons />} />
                <Route
                  path={ADMIN_PATH.ADMIN_SMART_MAPPING}
                  element={<SmartMapping />}
                />
                <Route
                  path={ADMIN_PATH.ACCOUNT_BY_ACCOUNT_ID}
                  element={<Accounts />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_REPORTS_SERVICES}
                  element={<AdminServicesReports />}
                />
                <Route path={ADMIN_PATH.ROLES} element={<Roles />} />
                <Route
                  path={ADMIN_PATH.PERMISSIONS}
                  element={<Permissions />}
                />
                <Route
                  path={ADMIN_PATH.MIGRATIONS_STARTER}
                  element={<MigrationsStarter />}
                />
                <Route
                  path={ADMIN_PATH.SURVEY_TICKET}
                  element={<SurveyTicket />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_DASHBOARD}
                  element={<AdminDashboard />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_CART_PREVIEW_STORES}
                  element={<AdminStores />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_CART_PREVIEW_PREPARED_STORES}
                  element={<AdminPreparedStores />}
                />
                <Route
                  path={ADMIN_PATH.MIGRATIONS_BY_RATES}
                  element={<ReportsDemoMigrationsRates />}
                />
                <Route
                  path={ADMIN_PATH.REPORTS_COUPONS}
                  element={<ReportsCoupons />}
                />
                <Route
                  path={ADMIN_PATH.WIZARD_STATS}
                  element={<ReportsWizardStats />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_CART_PREVIEW_CONFIGURATION}
                  element={<AdminPreviewConfig />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_CART_PREVIEW_LOGS}
                  element={<AdminPreviewLogs />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_CART_PREVIEW_REPORT}
                  element={<AdminPreviewReport />}
                />
                <Route
                  path={ADMIN_PATH.PROXIES_STATISTICS}
                  element={<ReportsProxiesStatistics />}
                />
                <Route
                  path={ADMIN_PATH.RE_MIGRATION_STATS}
                  element={<ReportsRemigrationStats />}
                />
                <Route
                  path={ADMIN_PATH.WIZARD_STEPS_FUNNEL}
                  element={<ReportsWizardStepsFunnel />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_MIGRATION_ISSUES}
                  element={<AdminMigrationsIssues />}
                />
                <Route
                  path={ADMIN_PATH.FEEDBACK_TABLE}
                  element={<AccountsFeedback />}
                />
                <Route
                  path={ADMIN_PATH.ADMIN_FILES_DOWNLOAD_BY_ID}
                  element={<AdminFilesDownload />}
                />
              </Route>
              {/* admin pages */}
            </Route>
          </Route>
        </Route>
        <Route path={AUTH_PATH.LOGOUT} element={<AuthorizationLogout />} />
        <Route path={USER_PATH.SHOPIFY_MODULE} element={<ShopifyModule />} />
        <Route path={ADMIN_PATH.ACCESS} element={<Access />} />
        <Route path={ADMIN_PATH.ACCESS_SUCCESS} element={<AccessSuccess />} />
      </Route>

      <Route
        path={ADMIN_PATH.ADMIN_AEROSQL_BY_MIGRATION_ID}
        element={<AuthProvider />}
      >
        <Route element={<LazyLayout offLayout />}>
          <Route index element={<AdminAeroSql />} />
        </Route>
      </Route>

      <Route element={<LazyLayout />}>
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};
export default AppRouter;
