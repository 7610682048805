export const FILE_TYPE = [
  {
    id: "Aabaco",
    type: "file",
  },
  {
    id: "AbleCommerce",
    type: "file",
  },
  {
    id: "Actinic",
    type: "file",
  },
  {
    id: "AmazonWebstore",
    type: "file",
  },
  {
    id: "ChannelAdvisor",
    type: "file",
  },
  {
    id: "CoreCommerce",
    type: "file",
  },
  {
    id: "CsvToCart",
    type: "file",
  },
  {
    id: "DigiShop",
    type: "file",
  },
  {
    id: "EPages",
    type: "file",
  },
  {
    id: "EkmPowerShop",
    type: "file",
  },
  {
    id: "Etsy",
    type: "file",
  },
  {
    id: "File2Cart",
    type: "file",
  },
  {
    id: "Lightspeed",
    type: "file",
  },
  {
    id: "Miva5",
    type: "file",
  },
  {
    id: "MivaFiles",
    type: "file",
  },
  {
    id: "NetworkSolutions",
    type: "file",
  },
  {
    id: "NopCommerceFiles",
    type: "file",
    inputs: ["source-clientId", "source-clientSecret"],
  },
  {
    id: "Prostores",
    type: "file",
  },
  {
    id: "Volusion",
    type: "file",
  },
  {
    id: "Yahoostore",
    type: "file",
  },
  {
    id: "YahoostoreCsv",
    type: "file",
  },
];

export const FILE_API = [
  {
    id: "AmeriCommerce",
    type: "api",
    inputs: ["source-accessToken"],
  },
  {
    id: "AspDotNetStorefront",
    type: "api",
    inputs: ["source-apiPass", "source-apiUser"],
  },
  {
    id: "BigCartel",
    type: "api",
  },
  {
    id: "Bigcommerce",
    type: "api",
    inputs: ["sourceaccessToken", "sourceapiPath", "sourceclientId"],
  },
  {
    id: "Dawanda",
    type: "api",
    inputs: ["source-apiKey"],
  },
  {
    id: "DiffbotParser",
    type: "api",
  },
  {
    id: "Ecwid",
    type: "api",
    inputs: ["sourcepostmanCollectionUrl"],
  },
  {
    id: "Entity3DCart",
    type: "api",
    inputs: ["source-public-key"],
  },
  {
    id: "Entity3DCartSoap",
    type: "api",
  },
  {
    id: "GoDaddy",
    type: "api",
  },
  {
    id: "GoldenPlanet",
    type: "api",
  },
  {
    id: "LemonStandApi",
    type: "api",
    inputs: ["sourceadmin-email", "sourceadmin-pwd"],
  },
  {
    id: "Neto",
    type: "api",
    inputs: ["source-apiKey"],
  },
  {
    id: "NopCommerce",
    type: "api",
    inputs: ["source-clientId", "source-clientSecret"],
  },
  {
    id: "Parser",
    type: "api",
  },
  {
    id: "Shopify",
    type: "api",
    inputs: ["sourceapiPassword"],
  },
  {
    id: "Sneakpeak",
    type: "api",
  },
  {
    id: "SparkPay",
    type: "api",
    inputs: ["source-accessToken"],
  },
  {
    id: "Squarespace",
    type: "api",
    inputs: ["source-adminEmail", "source-adminPassword"],
  },
  {
    id: "Weebly",
    type: "api",
    inputs: ["source-adminLogin", "source-adminPwd"],
  },
  {
    id: "Wix",
    type: "api",
    inputs: ["source-adminEmail", "source-adminPassword"],
  },
];

export const FILE_BRIDGE = [
  {
    id: "PrestaShop",
    type: "bridge",
    inputs: ["sourceadminLogin", "sourceadminPwd"],
  },
  {
    id: "AceShop",
    type: "bridge",
  },
  {
    id: "Arastta",
    type: "bridge",
  },
  {
    id: "Cart66",
    type: "bridge",
  },
  {
    id: "Creloaded",
    type: "bridge",
  },
  {
    id: "Cscart",
    type: "bridge",
  },
  {
    id: "Cubecart",
    type: "bridge",
  },
  {
    id: "DrupalCommerce",
    type: "bridge",
  },
  {
    id: "EasyDigitalDownloads",
    type: "bridge",
  },
  {
    id: "Gambio",
    type: "bridge",
  },
  {
    id: "Hhgmultistore",
    type: "bridge",
  },
  {
    id: "HikaShop",
    type: "bridge",
  },
  {
    id: "Interspire",
    type: "bridge",
  },
  {
    id: "Jigoshop",
    type: "bridge",
  },
  {
    id: "JooCart",
    type: "bridge",
  },
  {
    id: "JoomShopping",
    type: "bridge",
  },
  {
    id: "LemonStand",
    type: "bridge",
  },
  {
    id: "LoadedCommerce",
    type: "bridge",
  },
  {
    id: "Magento",
    type: "bridge",
  },
  {
    id: "Merchium",
    type: "bridge",
    inputs: [
      {
        name: "source-security-token",
        label: "Security Token",
        disabled: true,
      },
    ],
  },
  {
    id: "MijoShop",
    type: "bridge",
  },
  {
    id: "MiwoShop",
    type: "bridge",
  },
  {
    id: "Modified",
    type: "bridge",
  },
  {
    id: "Opencart",
    type: "bridge",
  },
  {
    id: "Oscmax2",
    type: "bridge",
  },
  {
    id: "Oscommerce22ms2",
    type: "bridge",
  },
  {
    id: "Oxid",
    type: "bridge",
  },
  {
    id: "Pinnacle",
    type: "bridge",
  },
  {
    id: "PrestaShopCloud",
    type: "bridge",
  },
  {
    id: "Shopp",
    type: "bridge",
  },
  {
    id: "Shopscript",
    type: "bridge",
  },
  {
    id: "Squirrelcart",
    type: "bridge",
  },
  {
    id: "Summercart",
    type: "bridge",
  },
  {
    id: "Sunshop",
    type: "bridge",
  },
  {
    id: "ThirtyBees",
    type: "bridge",
  },
  {
    id: "Tomatocart",
    type: "bridge",
  },
  {
    id: "Ubercart",
    type: "bridge",
  },
  {
    id: "Virtuemart",
    type: "bridge",
  },
  {
    id: "WPeStorePlugin",
    type: "bridge",
  },
  {
    id: "WPecommerce",
    type: "bridge",
  },
  {
    id: "Woocommerce",
    type: "bridge",
  },
  {
    id: "WordPress",
    type: "bridge",
  },
  {
    id: "Xcart",
    type: "bridge",
  },
  {
    id: "Xtcommerce",
    type: "bridge",
  },
  {
    id: "Zencart137",
    type: "bridge",
  },
];

export const colorByStatus = (status) => {
  if (status === "active") {
    return "#1677ff";
  }

  if (status === "success") {
    return "#52c41a";
  }

  return "#0091c9";
};

export const SELECT_WITH_FIELDS = {
  Orders: ["Customers"],
  Customers: ["Orders"],
};

export const SELECT_WITH_EXCEPTIONS = {
  FILTER: ["Orders"],
  PUSH: ["Customers"],
};

export const updateEntitiesSelectWith = (itemKey) =>
  SELECT_WITH_FIELDS[itemKey] || "";

export const updateEntitiesChildren = (children, plugin) => {
  const updatedChildren = children?.map((child) => {
    const pluginData = plugin?.[child?.key] && {
      cartId: plugin[child.key]?.cartId,
      notice: plugin[child.key]?.notice,
      pluginInstalled: plugin[child.key]?.pluginInstalled,
      entityKey: plugin[child.key]?.entityKey,
      storeType: plugin[child.key]?.storeType,
      title: plugin[child.key]?.title,
      type: plugin[child.key]?.type,
    };

    return {
      ...child,
      additional_options: pluginData,
      fields_select_with: updateEntitiesSelectWith(child.key),
      connect: child?.connect,
    };
  });

  return updatedChildren;
};

export const updateEntities = (entities, plugin) => {
  const data = entities?.map((item) => ({
    key: item?.key,
    name: item?.name,
    limit: "10 within demo",
    children: updateEntitiesChildren(item?.children, plugin),
    fields_select_with: updateEntitiesSelectWith(item?.key),
    additional_options: plugin?.[item?.key],
  }));

  return data;
};

export const updateFiles = (resources) => {
  if (resources?.db) {
    return [];
  }

  return Object.entries(resources || {})
    .map(([id, item], index) => ({
      id,
      key: index + 1,
      name: item.title,
      uploadedFiles: item.uploadedFiles,
      type: item.type,
      status: item.uploaded,
      required: item.required,
      comment: item.comment,
    }));
};

export const entityQuery = (entities) => {
  const data = entities?.map((item, index) => {
    if (index === 0) {
      return `&entities[]=${item}&`;
    }

    return `entities[]=${item}&`;
  });

  return data?.join("");
};

export const removeValueFromObject = (key, obj) => {
  if (obj?.[key]) {
    const { [key]: deletedValue, ...rest } = obj;
    const { sets: deletedSets, ...newRest } = rest;

    return {
      ...newRest,
      ...rest?.sets?.groups?.api,
      // ...rest?.sets?.groups?.admin,
    };
  }

  if (obj?.sets) {
    const { sets: deletedSets, ...newRest } = obj;

    return {
      ...newRest,
      // ...obj?.sets?.groups?.api,
    };
  }

  return {
    ...obj,
  };
};

export const removeAdminInputFromObject = (obj) => {
  const { url, video, sets, ...newObj } = obj;

  return newObj;
};
