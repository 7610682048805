export const USER_PATH = {
  REGISTRATION_QUIZ: "choose-business-role",
  MI_START_MIGRATION_PAGE: "start-here",
  MY_MIGRATIONS_LIST: "migrations/list",
  SHOPIFY_MODULE: ":fromTo?/migration-module/shopify",
  MY_MIGRATION_DETAILS_BY_ID: "migrations/details/index/id/:id",
  MY_MIGRATION_DEMO_RATE_BY_ID: "migrations/demo-rate/:id",
  PROFILE: "profile",
  DATA_MIGRATION_PAGE: "support-service/package",
  DATA_MIGRATION_PAGE_THANK_YOU_PAGE: "support-service/thank-you-page",
  MIGRATIONS_MAPPING_CLEAR: "support-service/package/mapping",
  CHANGE_PASSWORD: "change-password/email/:email/token/:token",
  WIZARD: "wizard",
  MIGRATIONS_WIZARD: "migrations/wizard",
  MIGRATIONS_WIZARD_FROM_TO: "migrations/wizard/from-:from-to-:to",
  MIGRATIONS_WIZARD_FROM: "migrations/wizard/from-:from",
  MIGRATIONS_WIZARD_TO: "migrations/wizard/to-:to",
  // MIGRATIONS_WIZARD_EDIT: "migrations/wizard/edit/:mId",
  WIZARD_STEP: "wizard/:screen",
  WIZARD_CREATE_MIGRATION: "wizard/create",
  WIZARD_STEP_PROGRESS: "wizard/:screen/:migrationId",
  WIZARD_STEP_BY_MIGRATION_ID: "wizard/:migrationId",
  WIZARD_STEP_ACCOUNT_SELECTION: "wizard/account-selection",
  WIZARD_STEP_SELECT_SOURCE: "wizard/select-source",
  UNSUBSCRIBE: "unsubscribe/email/:email/token/:token",
  INVOICE: "profile/invoice",
  FIRST_SCREEN: "first-screen",
  PROFILE_BALANCE_HISTORY: "profile#balance-history",
  RESTART_MIGRATION_BY_ID: ":fromTo?/migrations/restart-migration/:id",
  PAYMENTS_PROCESS: "payments/process-payment",
  PAYMENTS_SHOPIFY_IPN: "payments/shopify-ipn",
  LOYALTY_USERS: "partner/accounts",
  COMPLAINT_FORM: "complaint-form",
  MAPPINGS_BY_MIGRATION_ID: "migrations/mappings/:id",
  MAPPINGS: "migrations/mappings",
};
