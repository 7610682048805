import React, { useEffect, memo } from "react";

import { ReactComponent as SocialGIcon } from "../../../../assets/images/SocialGIcon.svg";
import {
  useSocialLoginMutation,
  useSocialLoginCallbackMutation,
} from "../../../../store/authentication/authService";
import LoadingModal from "../../../../components/LoadingModal";

import { Container, SocialButton } from "./styles";

const AuthSocialForm = ({ title }) => {
  const [login, { isLoading, isFetching }] = useSocialLoginMutation();
  const [
    loginCallback,
    { isLoading: isLoadingCallback, isFetching: isFetchingCallback },
  ] = useSocialLoginCallbackMutation();

  const handleLogin = async (provider) => {
    try {
      const res = await login({ social: provider }).unwrap();
      if (res?.payload?.redirect_url) {
        window.location.href = res.payload.redirect_url;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const state = params.get("state");

    if (code && state) {
      loginCallback({ social: "google", code })
        .unwrap()
        .then(() => {
          window.history.replaceState(
            {},
            document.title,
            window.location.pathname,
          );
        })
        .catch((error) => console.error(error));
    }
  }, [loginCallback]);

  return (
    <Container>
      <LoadingModal isLoading={isLoadingCallback || isFetchingCallback} />
      {/* <SocialButton */}
      {/*  onClick={() => handleLogin("facebook"} */}
      {/*  loading={isLoading && social === "facebook"} */}
      {/*  icon={<SocialFIcon />} */}
      {/*  color="#1877F2" */}
      {/* > */}
      {/*  {title} with Facebook */}
      {/* </SocialButton> */}

      <SocialButton
        onClick={() => handleLogin("google")}
        loading={isLoading}
        icon={<SocialGIcon />}
        color="#E22C2A"
      >
        {title} with Google
      </SocialButton>
    </Container>
  );
};

export default memo(AuthSocialForm);
