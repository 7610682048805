import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AUTH_PATH } from "../../router/path/auth";
import { useAuth } from "../../hooks/useAuth";
import { WIX_ENV } from "../../utils";
import {
  selectSettings,
  selectWixGetParam,
} from "../../store/settings/settingsSlice";
import ModalRole from "../../components/ModalRole";
import { useLazyGetAfterLoginQuery } from "../../store/authentication/authService";
import { usePublicConfig } from "../../hooks/usePublicConfig";

const AuthProviders = () => {
  const { options } = useSelector(selectSettings);
  const wixGetParam = useSelector(selectWixGetParam);
  const { user } = useAuth();
  const { env } = usePublicConfig();
  const [openModal, setOpenModal] = useState(false);
  const [triggerGetAfterLogin] = useLazyGetAfterLoginQuery();

  useEffect(() => {
    if (!WIX_ENV?.includes(env?.hostEnv)) return;

    if (Object.keys(wixGetParam || {}).length > 0) {
      triggerGetAfterLogin(wixGetParam)
        .unwrap()
        .catch((error) => console.error(error));
    }
  }, [wixGetParam]);

  useEffect(() => {
    if (!user?.business_role && options?.business_role?.required_in_popup) {
      setOpenModal(true);
    }
  }, [user, options]);

  return user?.id ? (
    <>
      {openModal && <ModalRole open={openModal} close={setOpenModal} />}
      <Outlet />
    </>
  ) : (
    <Navigate to={AUTH_PATH.LOGIN} replace />
  );
};

export default AuthProviders;
