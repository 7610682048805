import React, { useMemo } from "react";

import DynamicSvgActions from "../../../UI/DynamicSvgActions";
import { ReactComponent as SocialTableIcon } from "../../../../assets/images/SocialTableIcon.svg";
import Tooltip from "../../../UI/Tooltip";
import { STATUS_IN_DEMO_PROGRESS, STATUS_COMPLETED } from "../../utils/status";

import { Container, Title, TooltipText } from "./styles";

const HeaderAction = ({
  type,
  title,
  redirectByType,
  isIcon,
  tooltipTitle,
  csvLoading,
  status
}) => {
  const onClick = () => redirectByType(type);

  const isDisabled = useMemo(() =>
    (type === "exportCSV" && csvLoading)
    || (type === "killDemoProcess" && status !== STATUS_IN_DEMO_PROGRESS)
    || (type === "recent" && status !== STATUS_COMPLETED),
   [type, csvLoading, status]
  );

  return (
    <Container onClick={onClick} disabled={isDisabled}>
      <DynamicSvgActions imageName={type} />

      <Title>{title}</Title>

      {isIcon && (
        <Tooltip
          color="#0A1338"
          title={<TooltipText>{tooltipTitle}</TooltipText>}
          placement="bottom"
          border={8}
        >
          <SocialTableIcon />
        </Tooltip>
      )}
    </Container>
  );
};

export default HeaderAction;
