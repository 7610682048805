import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { openChat, redirectToLoginIfUnauthorized } from "../utils/providers";

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  const baseUrl = localStorage.getItem("host_api") || process.env.REACT_APP_HOST_API;
  const mirrorHost = localStorage.getItem("mirror_host") || process.env.REACT_APP_BACK_MIRROR_HOST || window.location.host;
  const fromSource = localStorage.getItem("source");
  const toTarget = localStorage.getItem("target");

  const customBaseQuery = fetchBaseQuery({
    baseUrl,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const { access_token } = getState().auth;
      const { store } = getState().settings;

      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }

      if (mirrorHost) {
        headers.set("X-Mirror-Host", mirrorHost);
      }

      if (fromSource) {
        headers.set("X-From-Cart", fromSource);
      }

      if (toTarget) {
        headers.set("X-To-Cart", toTarget);
      }

      if (args?.url === "/profile") {
        if (store.url) {
          headers.set("x-store-url", store?.url);
        }

        if (store.admin_url) {
          headers.set("x-store-admin-url", store?.admin_url);
        }
      }

      return headers;
    },
  });

  const result = await customBaseQuery(args, api, extraOptions);

  if (result.meta?.response.status >= 500) {
    openChat();
    alert('Connection timed out, please contact technical support for more details.');
  }

  redirectToLoginIfUnauthorized(result, args);

  return result;
};

export const BaseQueryParams = (reducerPath, tags) => {
  const baseQuery = createApi({
    baseQuery: baseQueryWithReAuth,
    endpoints: () => ({}),
    reducerPath,
    tagTypes: tags?.length ? tags : [],
  });

  return baseQuery;
};
