/* eslint-disable no-undef */
import { AUTH_PATH } from "../router/path/auth";
import { WIZARD_STEP_PATH } from "../router/path/wizard";
import { store } from "../store";

export const setRightRoute = (prefix, route, firstRoute) => {
  const authRoutes = Object.values(AUTH_PATH).flatMap((item) => [
    `/${item}`,
    `/${item}/`,
    `/${prefix}/${item}`,
    `/${prefix}/${item}/`,
  ]);
  const wizardRoutes = Object.values(WIZARD_STEP_PATH).flatMap((item) => [
    `/wizard/${item}`,
    `/wizard/${item}/`,
    `/${prefix}/${item}`,
    `/${prefix}/${item}/`,
  ]);

  if (route?.includes(`/${AUTH_PATH.OAUTH_CALLBACK_GOOGLE}`)) {
    window.location.href = firstRoute;
  }

  if (
    authRoutes.includes(route) ||
    wizardRoutes.includes(route) ||
    route === `/${prefix}` ||
    route === `/${prefix}/`
  ) {
    return "";
  }

  return route || "";
};
export const openChat = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('openChat');
  }
};

export const openChatWithMessage = (message) => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('closeChat');
    setTimeout(() => {
      HelpCrunch('typeUserMessage', message);
      HelpCrunch('openChat');
    }, 300);
  }
};

export const closeChat = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch("closeChat");
  }
};

export const hideChatWidget = () => {
  if (typeof HelpCrunch !== "undefined") {
    HelpCrunch('hideChatWidget');
  }
};

export const redirectToLoginIfUnauthorized = (result, args) => {
  const { access_token } = store.getState().auth;

  if (
    access_token &&
    args?.url !== "/profile" &&
    result.meta?.response.status === 401
  ) {
    window.location.reload();
  }
};