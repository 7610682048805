/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { usePublicConfig } from "../../hooks/usePublicConfig";
import { useMigrationSession } from "../../hooks/useMigrationSession";
import { openChat } from "../../utils/providers";

const HelpCrunchProvider = ({ children }) => {
  const { user } = useAuth();
  const { env } = usePublicConfig();
  const { hash } = useLocation();
  const migration = useMigrationSession();

  useEffect(() => {
    if (user?.id) {
      window.helpcrunchSettings = {
        organization: "cart2cart",
        appId: env?.helpcrunch_app_id ?? process.env?.REACT_APP_HELP_CRUNCH_ID,
        user: {
          user_id: user?.helpcrunch_user_hash,
          name: user?.firstname,
          email: user?.email,
        },
      };

      if (typeof HelpCrunch !== "undefined") {
        const name = [user?.firstname, user?.lastname].filter(Boolean).join(" ");
        const data = {
          email: user?.email,
          user_id: user?.helpcrunch_user_hash,
        };

        if (name) {
          data.name = name;
        }
        HelpCrunch("userAuth", data);
      }
    } else {
      if (typeof HelpCrunch !== "undefined") {
        HelpCrunch('logout', () => {});
      }

      window.helpcrunchSettings = {
        organization: "cart2cart",
        appId: env?.helpcrunch_app_id ?? process.env?.REACT_APP_HELP_CRUNCH_ID,
      };
    }
  }, [user?.id]);

  useEffect(() => {
    if (hash === "#showchat") {
      openChat();
    }
  }, [hash]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    if (user?.id) {
      window.dataLayer.push({
        event: "UserIdEvent",
        userId: user?.id,
      });
    }

    if (migration?.id) {
      window.dataLayer.push({
        event: "MigrationIdEvent",
        migrationId: migration?.id,
      });
    }
  }, [user?.id, migration?.id]);

  return <>{children}</>;
};

export default HelpCrunchProvider;
