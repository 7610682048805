import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { setToken } from "../../store/authentication/authSlice";
import { getCookie } from "../../utils";
import { setUserLogout } from "../../store/profile/profileSlice";
import { useLogoutMutation } from "../../store/authentication/authService";
import { useFormTo } from "../../hooks/useFormTo";

const TokenProvider = ({ children }) => {
  const { access_token } = useAuth();
  const [cookieToken, setCookieToken] = useState(() => getCookie("cart2cart_auth"));
  const cookieDomain = process.env?.REACT_APP_COOKIE_DOMAIN;
  const previousTokenRef = useRef(access_token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { prefix } = useFormTo();
  const [logout] = useLogoutMutation();

  const onLogout = async () => {
    try {
      await logout().unwrap();

      dispatch(setUserLogout());
      navigate(`${prefix}/`);
    } catch (error) {
      navigate(`${prefix}/`);
    }
  };

  useEffect(() => {
    if (window.self !== window.top) return;

    if (previousTokenRef.current !== access_token) {
      document.cookie = `cart2cart_auth=${access_token || ""}; path=/; ${cookieDomain ? `domain=${cookieDomain};` : ""}`;
      setCookieToken(() => getCookie("cart2cart_auth"));
      previousTokenRef.current = access_token;
    }
  }, [access_token]);

  useEffect(() => {
    if (window.self !== window.top) return;

    if (!cookieToken && access_token) {
      onLogout();
    }

    if (access_token !== cookieToken) {
      dispatch(setToken(cookieToken));
    }
  }, [cookieToken]);

  return children;
};

export default TokenProvider;