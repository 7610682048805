import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap');

  * {
    padding: 0;
    margin: 0;
    border: 0;

    ::-webkit-scrollbar-thumb {
      background-color: rgba(39, 71, 101, 0.2);
      border-radius: 20px;
    }

    ::-webkit-scrollbar-track {
      background: rgba(39, 71, 101, 0.06);
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    min-width: 320px;
    line-height: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul li {
    list-style: none;
  }

  img {
    vertical-align: top;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  button {
    cursor: pointer;
    color: inherit;
    background-color: transparent;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    box-shadow: none;
  }

  html, body, #root {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => theme.pallette.blue.primary};
    font-weight: 400;
    overflow: hidden;
  }

  :is([class*="ant-"]) {
    font-family: 'Poppins', sans-serif;
  }

  #root {
    height: 100vh;
    background-color: #F0F2F8;
    overflow: hidden;
    position: relative;
  }

  .notice_message a {
    font-weight: 500;
    color: #00AEEF;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.pallette.blue.link};
    }
  }

  .ant-dropdown-menu-title-content {
    .footerLink {
      &:hover {
        span {
          color: #00AEEF;
        }
      }
      
      span {
        color: #182353;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .headerLink {
      span {
        color: #182353;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      &:hover {
        span {
          color: #00AEEF;
        }
      }
    }
  }

  .userMenu {
    .ant-dropdown-menu {
      border-radius: 6px;
      max-height: 550px;
      overflow-y: auto;
      padding: 0;

      .ant-dropdown-menu-item {
        padding: 0;
      }

      .ant-dropdown-menu-item-disabled, .ant-dropdown-menu-item-disabled:hover {
        cursor: default;
      }
    }
  }

  .ant-select-dropdown
    .ant-select-item-option-content {
    white-space: normal;
  }

  .ant-tooltip {
    z-index: 998;

    .ant-tooltip-inner {
      @media (max-width: 767px) {
        margin: 0 10px;
      }
    }
  } 

  .intercom-lightweight-app {
    .intercom-lightweight-app-launcher {

      @media (max-width: 1300px) {
        bottom: 200px;
      }

      @media (max-width: 767px) {
        bottom: 20px;
      }
    }

    .intercom-lightweight-app-messenger {
      @media (max-width: 1300px) {
        bottom: 20px;
        right: 84px;
      }

      @media (max-width: 767px) {
        bottom: 84px;
        right: 20px;
      }
    }
  }
`;
