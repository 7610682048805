import React from "react";
import DynamicSvgComponent from "../components/UI/DynamicSvgComponent";

export const updateOptionItem = (options, Label) => {
  const items = options?.map((item) => ({
    value: item?.value,
    label: (
      <Label>
        <DynamicSvgComponent svgFilename={item?.value?.toLowerCase()} />{" "}
        {item?.label}
      </Label>
    ),
    picture: item?.picture,
  }));

  return items;
};
